import React from 'react';
import { Divider, Group, Paper, Title, Text, Image } from '@mantine/core';
import WeatherStat from 'components/WeatherStat';

import barfdt from 'barf-datetime';

class DailyList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {

        let data = this.props.data;

        if (!data || !Array.isArray(data)) {
            return null;
        }

        let items = data.map((el) => {

            

            let weatherName = null;
            let weatherDesc = null;
            let icon = null;
            if (el.weather && Array.isArray(el.weather) && el.weather.length > 0) {
                icon = (
                    <WeatherStat sm={true}>
                    <Image
                        src={'/api/wi/name/' + el.weather[0].icon}
                        alt={el.weather[0].description}
                        height={32}
                        width={32}
                        withPlaceholder
                    />
                    </WeatherStat>
                )
                weatherName = (
                    <Text style={{fontSize: '12px', fontWeight:'400'}}>{el.weather[0].main}</Text>
                )
                weatherDesc = (
                    <Text style={{fontSize: '12px', fontWeight:'400'}}>{el.weather[0].description}</Text>
                )
            }

            let timestamp = null;
            if (el.dt) {
                timestamp = (
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            width: '96%',
                            margin: '0 0 8px 0',
                            padding: '0'
                        }}
                    >
                        <Text style={{fontSize: '14px', fontWeight:'500'}}>{barfdt.dayName(new Date(el.dt * 1000))} {barfdt.formatDDMMYYYY(new Date(el.dt * 1000))}</Text>
                        {weatherDesc}
                    </div>
                )
            }

            let precipRain = null;
            if (el.rain) {
                precipRain = (
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            width: '96%',
                            margin: '0 0 8px 0',
                            padding: '0'
                        }}
                    >
                        <Text align="left" style={{margin: '0 0 8px 0',fontSize: '14px', fontWeight:'500'}}><span style={{fontSize: '10px', fontWeight:'400'}}>Rain:</span> {el.rain} mm</Text>
                    </div>
                )
            }
            let precipSnow = null;
            if (el.snow) {
                precipSnow = (
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            width: '96%',
                            margin: '0 0 8px 0',
                            padding: '0'
                        }}
                    >
                        <Text style={{margin: '0 0 8px 0',fontSize: '14px', fontWeight:'500'}}><span style={{fontSize: '10px', fontWeight:'400'}}>Snow:</span> {el.snow} mm</Text>
                    </div>
                )
            }

            /*
            let windstat = (
                <WeatherStat margin="8px 0 0 0" sm={true} unit="km/h" value={(el.wind_speed * 3.6).toFixed(1)} arrow={true} direction={el.wind_deg} />
            )
            */
            let windstat = (
                <WeatherStat margin="8px 0 0 0" sm={true} unit="m/s" value={(el.wind_speed).toFixed(1)} arrow={true} direction={el.wind_deg} />
            )
        
            let temp = null;
            if (el.temp && el.temp.min && el.temp.max) {
                temp = (
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            width: '96%',
                            margin: '0 0 8px 0',
                            padding: '0'
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                width: '96%',
                                margin: '0',
                                padding: '0'
                            }}
                        >
                            <Text align="left" style={{fontSize: '18px', fontWeight:'500', width: '64px'}}>{(el.temp.min).toFixed(1)}°C </Text>
                            <Text align="center" style={{fontSize: '16px', fontWeight:'400'}}>Min / Max</Text>
                            <Text align="right" style={{fontSize: '18px', fontWeight:'500', width: '64px'}}>{(el.temp.max).toFixed(1)}°C</Text>
                        </div>
                    </div>
                )
            }

            let temp2 = null;
            if (el.temp && el.feels_like) {
                temp2 = (
                    
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '96%',
                            margin: '0 0 8px 0',
                            padding: '0'
                        }}
                    >

                        <Divider />

                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                width: '96%',
                                margin: '0',
                                padding: '0'
                            }}
                        >
                            <Text style={{fontSize: '12px', fontWeight:'400', width: '64px'}}>Temp</Text>
                            <Text style={{fontSize: '12px', fontWeight:'400'}}></Text>
                            <Text align="right" style={{fontSize: '12px', fontWeight:'400', width: '64px'}}>Feels like</Text>
                        </div>

                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                width: '96%',
                                margin: '0',
                                padding: '0'
                            }}
                        >
                            <Text style={{fontSize: '14px', fontWeight:'500', width: '64px'}}>{(el.temp.morn).toFixed(1)}°C</Text>
                            <Text align="center" style={{fontSize: '12px', fontWeight:'400'}}>Morning</Text>
                            <Text align="right" style={{fontSize: '14px', fontWeight:'500', width: '64px'}}>{(el.feels_like.morn).toFixed(1)}°C</Text>
                            
                        </div>

                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                width: '96%',
                                margin: '0',
                                padding: '0'
                            }}
                        >
                            <Text style={{fontSize: '14px', fontWeight:'500', width: '64px'}}>{(el.temp.day).toFixed(1)}°C</Text>
                            <Text align="center" style={{fontSize: '12px', fontWeight:'400'}}>Day</Text>
                            <Text align="right" style={{fontSize: '14px', fontWeight:'500', width: '64px'}}>{(el.feels_like.day).toFixed(1)}°C</Text>
                            
                        </div>

                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                width: '96%',
                                margin: '0',
                                padding: '0'
                            }}
                        >
                            <Text style={{fontSize: '14px', fontWeight:'500', width: '64px'}}>{(el.temp.eve).toFixed(1)}°C</Text>
                            <Text align="center" style={{fontSize: '12px', fontWeight:'400'}}>Evening</Text>
                            <Text align="right" style={{fontSize: '14px', fontWeight:'500', width: '64px'}}>{(el.feels_like.eve).toFixed(1)}°C</Text>
                            
                        </div>

                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                width: '96%',
                                margin: '0',
                                padding: '0'
                            }}
                        >
                            <Text style={{fontSize: '14px', fontWeight:'500', width: '64px'}}>{(el.temp.night).toFixed(1)}°C</Text>
                            <Text align="center" style={{fontSize: '12px', fontWeight:'400'}}>Night</Text>
                            <Text align="right" style={{fontSize: '14px', fontWeight:'500', width: '64px'}}>{(el.feels_like.night).toFixed(1)}°C</Text>
                            
                        </div>

                        <Divider />

                    </div>
                )
            }

/*
<Text style={{fontSize: '16px', fontWeight:'500'}}><span style={{fontSize: '10px', fontWeight:'400'}}>Feels like:</span> {(el.feels_like).toFixed(1)}°C</Text>
*/

            let preshum = (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        width: '96%',
                        margin: '0 0 8px 0',
                        padding: '0'
                    }}
                >
                    <Text style={{fontSize: '14px', fontWeight:'500'}}><span style={{fontSize: '10px', fontWeight:'400'}}>Pressure:</span> {el.pressure} kPa</Text>
                    <div style={{width: '8px'}} />
                    <Text style={{fontSize: '14px', fontWeight:'500'}}><span style={{fontSize: '10px', fontWeight:'400'}}>Humidity:</span> {el.humidity} %</Text>
                </div>
            )

            let cloudvis = (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        width: '96%',
                        margin: '0 0 8px 0',
                        padding: '0'
                    }}
                >
                    <Text style={{fontSize: '14px', fontWeight:'500'}}><span style={{fontSize: '10px', fontWeight:'400'}}>Clouds:</span> {el.clouds} %</Text>
                </div>
            )

            /*
            
              Daily data
              {
                "dt": 1646132400,
                "sunrise": 1646115149,
                "sunset": 1646153675,
                "moonrise": 1646116200,
                "moonset": 1646146500,
                "moon_phase": 0.95,
                "temp": {
                    "day": 0.9,
                    "min": 0.9,
                    "max": 3.37,
                    "night": 1.9,
                    "eve": 3.05,
                    "morn": 1.56
                },
                "feels_like": {
                    "day": -4.25,
                    "night": -1.77,
                    "eve": -0.66,
                    "morn": -4.78
                },
                "pressure": 1027,
                "humidity": 95,
                "dew_point": 0.19,
                "wind_speed": 9.41,
                "wind_deg": 173,
                "wind_gust": 16.36,
                "weather": [
                    {
                    "id": 616,
                    "main": "Snow",
                    "description": "regn og snevejr",
                    "icon": "13d"
                    }
                ],
                "clouds": 100,
                "pop": 0.86,
                "rain": 0.19,
                "snow": 1.58,
                "uvi": 0.62
                },


              */

            return (
                <Paper padding={4} shadow={0} radius={4} style={{width: '100%', maxWidth: '448px'}} withBorder key={el.dt}>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'flex-start',
                            justifyContent: 'flex-start',
                            
                            width: '100%',
                            margin: '0',
                            padding: '0'
                        }}
                    >

                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'flex-start',
                                height: '100%',
                                width: '64px',
                                margin: '4px 8px 0 4px',
                                padding: '0'
                            }}
                        >
                            {icon}
                            {weatherName}

                            {windstat}
                            
                        </div>

                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'flex-start',
                                height: '100%',
                                width: '100%',
                                margin: '0',
                                padding: '0'
                            }}
                        >

                            {timestamp}
                            {temp}

                            {temp2}

                            {cloudvis}
                            {preshum}

                            {precipRain}
                            {precipSnow}

                        </div>

                        {/*
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-end',
                                justifyContent: 'flex-start',
                                height: '100%',
                                width: '52px',
                                margin: '4px 4px 0 8px',
                                padding: '0'
                            }}
                        >
                            {windstat}
                        </div>
                        */}
                        
                        
                    </div>
                </Paper>
            )
        })


        return (

            <Group style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'flex-start',
                height: '100%',
                width: '100%',
                margin: '0',
                padding: '0'
            }}>

                {items}

            </Group>

        );
    }
}

export default DailyList;

import React from 'react';
import { Title, Text, Image } from '@mantine/core';
import WeatherStat from 'components/WeatherStat';

import barfdt from 'barf-datetime';

class LocationHeader extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {

        let locationname = this.props.name;
        let data = this.props.data;

        if (!locationname || !data) {
            return null;
        }

        let weatherName = null;
        let iconid = -1;
        let icon = null;
        if (data.weather && data.weather.length > 0 && data.weather[0].icon) {
            iconid = data.weather[0].icon;
            icon = (
                <WeatherStat>
                    <Image
                        src={'/api/wi/name/' + iconid}
                        alt={data.weather[0].description}
                        height={48}
                        width={48}
                        withPlaceholder
                    />
                </WeatherStat>
            )
            weatherName = (
                <Text size="sm">{data.weather[0].main}</Text>
            )
        }

        let precip = null;
        if (data.rain) {
            precip = (
                <Text color="dimmed" size="sm">{data.rain['1h']} mm</Text>
            )
        }
        if (data.snow) {
            precip = (
                <Text color="dimmed" size="sm">{data.snow['1h']} mm</Text>
            )
        }

        let timestamp = null;
        if (data.dt) {
            timestamp = (
                <Text color="dimmed" size="xs">Updated {barfdt.formatHMM(new Date(data.dt * 1000))}</Text>
            )
        }

        let sunrise = null;
        if (data.sunrise) {
            sunrise = (
                <Text color="dimmed" size="sm">Sunrise {barfdt.formatHMM(new Date(data.sunrise * 1000))}</Text>
            )
        }

        let sunset = null;
        if (data.sunset) {
            sunset = (
                <Text color="dimmed" size="sm">Sunset {barfdt.formatHMM(new Date(data.sunset * 1000))}</Text>
            )
        }




        return (

            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    height: '100%',
                    width: '100%',
                    margin: '1rem 0 1rem 0',
                    padding: '0px'
                }}
            >
                <div>

                    {timestamp}
                    <Title order={3}>{locationname}</Title>

                    {sunrise}
                    {sunset}
                </div>

                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '100%',
                        
                        padding: '0'
                    }}
                >
                    {icon}
                    {weatherName}
                    {precip}
                </div>
            </div>

        );
    }
}

export default LocationHeader;

import React from 'react';
import { useState } from 'react';
import { Container, Group, Paper, Title, Text, Image, Tabs } from '@mantine/core';
import LocationHeader from 'components/LocationHeader';
import WeatherStat from 'components/WeatherStat';
import HourlyList from 'components/HourlyList';
import DailyList from 'components/DailyList';

import { useSwipeable } from 'react-swipeable';

import { BarChart, Bar, ResponsiveContainer, XAxis, YAxis } from "recharts";


import barfdt from 'barf-datetime';



function LocationPageParent(props) {

    const [actTab, setTab] = useState(0)

    const handlers = useSwipeable({
        onSwipedLeft: (eventData) => {
            //console.log("User Swiped Left!", eventData);
            let newtab = (actTab + 1);
            if (newtab > 2) newtab = 2;
            setTab(newtab)
        },
        onSwipedRight: (eventData) => {
            //console.log("User Swiped Right!", eventData);
            let newtab = (actTab - 1);
            if (newtab < 0) newtab = 0;
            setTab(newtab)
        }
    });


    return (<div {...handlers}><LocationPage {...props} activetab={actTab} /> </div>);



}




class LocationPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            locid: props.locationid,
            activeTab: props.activetab || 0
        };
        this.intUpdate = null;
    }

    pullData = () => {
        clearInterval(this.intUpdate);

        let locid = this.props.locationid;

        if (locid > 0) {
            fetch('/api/loc/' + locid)
                .then((response) => {
                    return response.json()
                })
                .then((weatherdata) => {
                    let newState = this.state;
                    let maxval = -1;
                    if (weatherdata.minutely && Array.isArray(weatherdata.minutely)) {
                        for (let i=0; i<weatherdata.minutely.length; i++) {
                            //weatherdata.minutely[i].precipitation = Math.random();
                            if (weatherdata.minutely[i].precipitation > maxval) maxval = weatherdata.minutely[i].precipitation;

                            //console.log(barfdt.formatHHMMSS(new Date(weatherdata.minutely[i].dt * 1000)))
                        }
                    }
                    //console.log(maxval);
                    if (maxval > 0.0) {
                        weatherdata.minutelyMax = Math.trunc(maxval + 1);
                    } else {
                        weatherdata.minutelyMax = 0;
                    }
                    newState.data = weatherdata;
                    this.setState(newState)
                })
                .catch((err) => {
                    console.log(err)
                })
        }
        this.intUpdate = setInterval(this.pullData, 60000)
    }

    setActiveTab = (value) => {
        let tempstate = this.state;
        tempstate.activeTab = value;
        this.setState(tempstate);
    }

    componentDidMount = () => {
        this.pullData();
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.locationid !== this.props.locationid) {
            this.pullData();
        }
        if (prevProps.activetab != this.props.activetab) {
            if (this.props.activetab != this.state.activeTab) {
                this.setActiveTab(this.props.activetab);
            }
        }
    }

    componentWillUnmount = () => {
        clearInterval(this.intUpdate);
    }



    render() {


        let locName = null;
        if (this.props.locationid == 1) {
            locName = 'Fredericia'
        } else if (this.props.locationid == 2) {
            locName = 'Slagelse'
        }

        let locHeader = null;
        if (locName && this.state.data) {
            locHeader = (
                <LocationHeader name={locName} data={this.state.data.current} />
            )
        }

        let chart = null;
        if (this.state.data && this.state.data.minutelyMax > 0.0 && this.state.data.minutely && Array.isArray(this.state.data.minutely)) {
            chart = (
                <div style={{width: '100%', maxWidth: '512px'}}>
                    <Text align="center" style={{fontSize: '12px', fontWeight:'400'}}>Precipitation (1 hour)</Text>
                
                    <ResponsiveContainer width="100%" height={100} >
                        
                        <BarChart barGap={0} barCategoryGap={0} data={this.state.data.minutely}>
                            <YAxis 
                                style={{fontSize:'11px'}}
                                width={20} 
                                axisLine={false} 
                                tickLine={false} 
                                interval="preserveStartEnd"
                                ticks={[0, this.state.data.minutelyMax]} 
                            />
                            <Bar dataKey="precipitation" fill="#8884d8" />
                        </BarChart>
                    </ResponsiveContainer>

                </div>
            )
        }

        /*
        let windstat = null;
        if (this.state.data && this.state.data.current) {
            windstat = (
                <WeatherStat caption="Wind" unit="km/h" value={(this.state.data.current.wind_speed * 3.6).toFixed(1)} arrow={true} direction={this.state.data.current.wind_deg} />
            )
        }
        */

        let windstat = null;
        if (this.state.data && this.state.data.current) {
            windstat = (
                <WeatherStat caption="Wind" unit="m/s" value={(this.state.data.current.wind_speed).toFixed(1)} arrow={true} direction={this.state.data.current.wind_deg} />
            )
        }

        let tempstat = null;
        if (this.state.data && this.state.data.current) {
            tempstat = (
                <WeatherStat xlg={true} caption="Temperature" unit="°C" value={(this.state.data.current.temp).toFixed(1)} />
            )
        }

        let feelstat = null;
        if (this.state.data && this.state.data.current) {
            feelstat = (
                <WeatherStat xlg={true} caption="Feels like" unit="°C" value={(this.state.data.current.feels_like).toFixed(1)} />
            )
        }

        let cloudstat = null;
        if (this.state.data && this.state.data.current) {
            cloudstat = (
                <WeatherStat caption="Clouds" unit="%" value={this.state.data.current.clouds || '0'} />
            )
        }

        let visibilitystat = null;
        if (this.state.data && this.state.data.current) {
            if (this.state.data.current.visibility > 1000) {
                visibilitystat = (
                    <WeatherStat caption="Visibility" unit="km" value={(this.state.data.current.visibility / 1000).toFixed(1)} />
                )
            } else {
                visibilitystat = (
                    <WeatherStat caption="Visibility" unit="m" value={this.state.data.current.visibility} />
                )
            }
        }

        let uvistat = null;
        if (this.state.data && this.state.data.current) {
            uvistat = (
                <WeatherStat caption="UV Index" value={(this.state.data.current.uvi).toFixed(1)} />
            )
        }

        let humiditystat = null;
        if (this.state.data && this.state.data.current) {
            humiditystat = (
                <WeatherStat caption="Humidity" unit="%" value={this.state.data.current.humidity || '0'} />
            )
        }

        let pressurestat = null;
        if (this.state.data && this.state.data.current) {
            pressurestat = (
                <WeatherStat caption="Pressure" unit="hPa" value={this.state.data.current.pressure || '0'} />
            )
        }

        let hourlydiv = null;
        if (this.state.data && this.state.data.hourly) {
            //hourlydiv = <pre>{JSON.stringify(this.state.data.hourly, null, 2)}</pre>
            hourlydiv = <HourlyList data={this.state.data.hourly} />
        }

        let dailydiv = null;
        if (this.state.data && this.state.data.daily) {
            //hourlydiv = <pre>{JSON.stringify(this.state.data.hourly, null, 2)}</pre>
            dailydiv = <DailyList data={this.state.data.daily} />
        }


        let tabbar = (
            <Tabs grow tabPadding="md" active={this.state.activeTab} onTabChange={this.setActiveTab}>
                <Tabs.Tab label="Current">
                    <Group position="center" spacing="lg" >

                        {tempstat}
                        {feelstat}

                        {windstat}
                        {cloudstat}
                        {visibilitystat}

                        {uvistat}
                        {humiditystat}
                        {pressurestat}
                    </Group>
                </Tabs.Tab>
                <Tabs.Tab label="Hourly">
                    {hourlydiv}
                </Tabs.Tab>
                <Tabs.Tab label="Daily">
                    {dailydiv}
                    {/*
                    <pre>{JSON.stringify(this.state.data, null, 2)}</pre>
                    */}
                </Tabs.Tab>
            </Tabs>
        )

        return (
            <Container fluid padding="xs">

                {locHeader}

                {chart}


                {tabbar}




            </Container>

        );
    }
}

export default LocationPageParent;

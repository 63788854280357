import React from 'react';
import { Title, Text, Image } from '@mantine/core';

class WeatherStat extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }



    render() {

        let unit = this.props.unit;
        let value = this.props.value;
        let arrow = this.props.arrow || false;
        let direction = parseInt(this.props.direction, 10) || 0;
        let caption = this.props.caption;

        let datadiv = null;
        if (unit && value) {
            datadiv = (
                <p>{value} <span>{unit}</span></p>
            )
        } else if (!unit && value) {
            datadiv = (
                <p>{value}</p>
            )
        }
        if (!datadiv) {
            datadiv = (
                <span>
                    {this.props.children}
                </span>
            )
        }

        let arrowdiv = null;
        if (arrow != false) {
            arrowdiv = (
                <div
                    className="arrow"
                    style={{ transform: 'rotate(' + direction + 'deg)' }}
                >

                </div>
            )
        }

        let captiondiv = null;
        if (caption) {
            captiondiv = (
                <Title order={6} align="center" style={{margin: '0 0 8px 0'}} >
                    <Text
                        color="dimmed"
                        inherit
                        component="span"
                    >
                        {caption}
                    </Text>
                </Title>
            )
        }

        let compassclass = 'compass';
        if (this.props.lg) {
            compassclass = compassclass + ' lg';
        } else if (this.props.sm) {
            compassclass = compassclass + ' sm';
        }

        let mainstyle={display:'inline-block'};
        if (this.props.margin) mainstyle.margin = this.props.margin;

        return (

            <div style={mainstyle} onClick={this.props.onClick}>
                
                {captiondiv}
                <div className={compassclass} >
                    <div className="speed" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        {datadiv}
                    </div>
                    {arrowdiv}
                </div>
                
            </div>

        );
    }
}

export default WeatherStat;

import { useState } from 'react';
import { AppShell, Burger, Header, MediaQuery, Navbar, Menu, Divider, Title, Text, useMantineTheme } from '@mantine/core';
import { useLocalStorageValue } from '@mantine/hooks';

import LocationPage from 'components/LocationPage';

const AppMain = () => {
    const [selectedLocation, setSelectedLocation] = useLocalStorageValue({ key: 'selectedlocation', defaultValue: '1' });
    //const [selectedLocation, setSelectedLocation] = useState(1);
    const theme = useMantineTheme();

    return (
        <AppShell
            navbarOffsetBreakpoint="sm"
            fixed
            padding={0}
            style={{margin: 'auto', width: '100%', maxWidth: '512px', alignItems: 'center', justifyContent: 'space-around'}}
            header={
                <Header height={64} padding="md">
                    <div 
                        style={{ 
                            display: 'flex', 
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            height: '100%' ,
                            maxWidth: '512px',
                            margin: 'auto'
                        }}
                    >

                        <Title order={2}>
                            <Text
                                weight={700}
                                inherit
                                component="span"
                            >
                                Casa Weather
                            </Text>
                        </Title>

                        <Menu >
                            <Menu.Label>Locations</Menu.Label>
                            <Menu.Item onClick={() => {setSelectedLocation(1)}}>
                                Fredericia
                            </Menu.Item>
                            
                            <Menu.Item onClick={() => {setSelectedLocation(2)}}>
                                Slagelse
                            </Menu.Item>
                            
                        </Menu>

                    </div>
                </Header>
            }
        >
            
            <LocationPage locationid={selectedLocation}  />    
            
        
        </AppShell>
    );
}

export default AppMain;
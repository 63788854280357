import React from 'react';
import { hot } from 'react-hot-loader';
import { Container, MantineProvider } from '@mantine/core';
import AppMain from 'components/AppMain';

import '../assets/scss/App.scss'

//import LocationPage from 'components/LocationPage';
//import { BrowserRouter, Route, Routes, Link } from 'react-router-dom';


class App extends React.Component {
  render() {
    return (
      <MantineProvider>
            <AppMain />
      </MantineProvider>
        
    );
  }
}

export default hot(module)(App);

import React from 'react';
import { Group, Paper, Title, Text, Image } from '@mantine/core';
import WeatherStat from 'components/WeatherStat';

import barfdt from 'barf-datetime';

class HourlyList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {

        let data = this.props.data;

        if (!data || !Array.isArray(data)) {
            return null;
        }

        let items = data.map((el) => {

            let timestamp = null;
            if (el.dt) {
                timestamp = (
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            width: '96%',
                            margin: '0',
                            padding: '0'
                        }}
                    >
                        <Text style={{fontSize: '12px', fontWeight:'500'}}>{barfdt.formatHMM(new Date(el.dt * 1000))}</Text>
                        <Text style={{fontSize: '12px', fontWeight:'400'}}>{barfdt.dayName(new Date(el.dt * 1000))} {barfdt.formatDDMMYYYY(new Date(el.dt * 1000))}</Text>
                    </div>
                )
            }

            let weatherName = null;
            let icon = null;
            if (el.weather && Array.isArray(el.weather) && el.weather.length > 0) {
                icon = (
                    <WeatherStat sm={true}>
                    <Image
                        src={'/api/wi/name/' + el.weather[0].icon}
                        alt={el.weather[0].description}
                        height={32}
                        width={32}
                        withPlaceholder
                    />
                    </WeatherStat>
                )
                weatherName = (
                    <Text size="xs">{el.weather[0].main}</Text>
                )
            }
            let precip = null;
            if (el.rain) {
                precip = (
                    <Text style={{fontSize: '10px', fontWeight:'400'}}>{el.rain['1h']} mm</Text>
                )
            }
            if (el.snow) {
                precip = (
                    <Text style={{fontSize: '10px', fontWeight:'400'}}>{el.snow['1h']} mm</Text>
                )
            }

            /*
            let windstat = (
                <WeatherStat margin="8px 0 8px 0" sm={true} unit="km/h" value={(el.wind_speed * 3.6).toFixed(1)} arrow={true} direction={el.wind_deg} />
            )
            */
            let windstat = (
                <WeatherStat margin="8px 0 8px 0" sm={true} unit="m/s" value={(el.wind_speed).toFixed(1)} arrow={true} direction={el.wind_deg} />
            )
        
            let temp = (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        width: '96%',
                        margin: '8px 0 0 0',
                        padding: '0'
                    }}
                >
                    <Text style={{fontSize: '18px', fontWeight:'500'}}>{(el.temp).toFixed(1)}°C</Text>
                    <Text style={{fontSize: '16px', fontWeight:'500'}}><span style={{fontSize: '10px', fontWeight:'400'}}>Feels like:</span> {(el.feels_like).toFixed(1)}°C</Text>
                </div>
            )

            let preshum = (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        width: '96%',
                        margin: '8px 0 0 0',
                        padding: '0'
                    }}
                >
                    <Text style={{fontSize: '14px', fontWeight:'500'}}><span style={{fontSize: '10px', fontWeight:'400'}}>Pressure:</span> {el.pressure} kPa</Text>
                    <div style={{width: '8px'}} />
                    <Text style={{fontSize: '14px', fontWeight:'500'}}><span style={{fontSize: '10px', fontWeight:'400'}}>Humidity:</span> {el.humidity} %</Text>
                </div>
            )

            let cloudvis = (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        width: '96%',
                        margin: '8px 0 0 0',
                        padding: '0'
                    }}
                >
                    <Text style={{fontSize: '14px', fontWeight:'500'}}><span style={{fontSize: '10px', fontWeight:'400'}}>Clouds:</span> {el.clouds} %</Text>
                    <Text style={{fontSize: '14px', fontWeight:'500'}}><span style={{fontSize: '10px', fontWeight:'400'}}>Visibility:</span> {(el.visibility / 1000).toFixed(1)} km</Text>
                </div>
            )

            /*
            Hourly data
            {
                "dt": 1645797600,
                "temp": 5.4,
                "feels_like": 1.69,
                "pressure": 1009,
                "humidity": 73,
                "dew_point": 0.96,
                "uvi": 0.44,
                "clouds": 14,
                "visibility": 10000,
                "wind_speed": 5.32,
                "wind_deg": 287,
                "wind_gust": 8.2,
                "weather": [
                  {
                    "id": 801,
                    "main": "Clouds",
                    "description": "få skyer",
                    "icon": "02d"
                  }
                ],
                "pop": 0.13
              },





              Daily data
              {
                "dt": 1646132400,
                "sunrise": 1646115149,
                "sunset": 1646153675,
                "moonrise": 1646116200,
                "moonset": 1646146500,
                "moon_phase": 0.95,
                "temp": {
                    "day": 0.9,
                    "min": 0.9,
                    "max": 3.37,
                    "night": 1.9,
                    "eve": 3.05,
                    "morn": 1.56
                },
                "feels_like": {
                    "day": -4.25,
                    "night": -1.77,
                    "eve": -0.66,
                    "morn": -4.78
                },
                "pressure": 1027,
                "humidity": 95,
                "dew_point": 0.19,
                "wind_speed": 9.41,
                "wind_deg": 173,
                "wind_gust": 16.36,
                "weather": [
                    {
                    "id": 616,
                    "main": "Snow",
                    "description": "regn og snevejr",
                    "icon": "13d"
                    }
                ],
                "clouds": 100,
                "pop": 0.86,
                "rain": 0.19,
                "snow": 1.58,
                "uvi": 0.62
                },


              */

            return (
                <Paper padding={4} shadow={0} radius={4} style={{width: '100%', maxWidth: '448px'}} withBorder key={el.dt}>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'flex-start',
                            justifyContent: 'flex-start',
                            
                            width: '100%',
                            margin: '0',
                            padding: '0'
                        }}
                    >

                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'flex-start',
                                height: '100%',
                                width: '64px',
                                margin: '4px 8px 0 4px',
                                padding: '0'
                            }}
                        >
                            {icon}
                            {weatherName}
                            {precip}
                            {windstat}
                        </div>

                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'flex-start',
                                height: '100%',
                                width: '100%',
                                margin: '0',
                                padding: '0'
                            }}
                        >

                            {timestamp}
                            {temp}
                            {cloudvis}
                            {preshum}

                        </div>

                        {/*
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-end',
                                justifyContent: 'flex-start',
                                height: '100%',
                                width: '52px',
                                margin: '4px 4px 0 8px',
                                padding: '0'
                            }}
                        >
                            {windstat}
                        </div>
                        */}
                        
                        
                    </div>
                </Paper>
            )
        })


        return (

            <Group style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'flex-start',
                height: '100%',
                width: '100%',
                margin: '0',
                padding: '0'
            }}>

                {items}

            </Group>

        );
    }
}

export default HourlyList;
